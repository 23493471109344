import { ProductCard_ProductType as ProductCardProductType } from '__generated__/graphql-types';

import {
  ExternalCertificateS12n,
  NormalS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import ROUTE_CONFIG from 'bundles/unified-description-page-common/constants/routeConfig';

export const COLLECTION_TYPENAMES = {
  COURSES: 'CoursesV1',
  DEGREE_LISTS: 'DegreeListsV3',
  DEGREE_LIST_MASTERTRACK: 'DegreeListV3Mastertrack',
  DEGREE_LIST_UNIVERSITY_CERTIFICATE: 'DegreeListV3UniversityCertificate',
  DISCO_COLLECTIONS_PRODUCT_COLLECTION: 'DiscoveryCollections_productCollection',
  DISCO_COLLECTIONS_GUIDED_PROJECT: 'DiscoveryCollections_guidedProject',
  DISCO_COLLECTIONS_PROJECT: 'DiscoveryCollections_project',
  DISCO_COLLECTIONS_PROFESSIONAL_CERT: 'DiscoveryCollections_professionalCertificate',
  DISCO_COLLECTIONS_SPECIALIZATION: 'DiscoveryCollections_specialization',
  DISCO_COLLECTIONS_COURSE: 'DiscoveryCollections_course',
  PREMIUM_PRODUCT_COLLECTION: 'PremiumProductCollection',
  STACKABLE_DEGREES: 'Degree_Degree',
  STACKABLE_SPECIALIZATION: 'Specialization_Specialization',
  CLIPS: 'Clip_CourseItem',
} as const;

const ENTITY_TYPE = {
  S12N: 's12n',
  COURSE: 'course',
  DEGREE: 'degree',
  MASTERTRACK: 'mastertrack',
  CERTIFICATE: 'certificate',
  CLIP: 'clip',
} as const;

export const COLLECTION_LABELS = {
  MASTERTRACKS: '::MASTERTRACKS',
  UNIVERSITY_CERTIFICATES: '::UNIVERSITY_CERTIFICATES',
  STACKABLE_DEGREES: '::STACKABLE_DEGREES',
  STACKABLE_SPECIALIZATIONS: '::STACKABLE_SPECIALIZATIONS',
} as const;

// TODO: when BE change to snake_case format, remove it
export const PREMIUM_PRODUCT_TYPENAMES = {
  BACHELORS_DEGREE: 'BachelorsDegree',
  MASTERS_DEGREE: 'MastersDegree',
  POSTGRADUATE_DIPLOMA: 'PostgraduateDiploma',
  MASTERTRACK: 'MasterTrack',
  UNIVERSITY_CERTIFICATE: 'UniversityCertificate',
};

// The endpoint getStackableDegree will provide the product typename in snake_case format. This is the desired format for our eventual use.
export const PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE = {
  BACHELORS_DEGREE: 'BACHELORS_DEGREE',
  MASTERS_DEGREE: 'MASTERS_DEGREE',
  POSTGRADUATE_DIPLOMA: 'POSTGRADUATE_DIPLOMA',
  MASTERTRACK: 'MASTER_TRACK',
  UNIVERSITY_CERTIFICATE: 'UNIVERSITY_CERTIFICATE',
  PROFESSIONAL_CERTIFICATE_S12N: 'PROFESSIONAL_CERTIFICATE_S12N',
};

export const PROGRAM_CURRICULUM_TYPENAMES = {
  COURSE: 'CourseTypeMetadataV1_standardCourseMember',
  GUIDED_PROJECT: 'CourseTypeMetadataV1_rhymeProjectMember',
  PROJECT: 'CourseTypeMetadataV1_projectMember',
};

export const defaultResponsiveProperties = [
  { breakpoint: 690, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false } },
  { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false } },
  { breakpoint: 1275, settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false } },
  { breakpoint: 100000, settings: { slidesToShow: 4, slidesToScroll: 4, infinite: false } },
];

/**
 * @deprecated
 *  Used for the old product card image component. The new value lives in the product-card bundle
 * under `PRODUCT_CARD_V2_IMAGE_DEFAULT_VALUES` in static/bundles/product-card/constants.ts
 */
export const PRODUCT_CARD_IMAGE_DEFAULT_VALUES = { height: 216, width: 265, fit: 'crop' };

export const COLLECTION_CAROUSEL_PRODUCT_CARD_IMAGE_HEIGHT = 204;

export const BROWSE_RESPONSIVE_PROPERTY_BREAKPOINTS = [
  { breakpoint: 690, settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false } },
  { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false } },
  {
    breakpoint: 1560,
    settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false },
  },
];

export const getEntityTypeFromTypename = (
  typename?: string
): (typeof ENTITY_TYPE)[keyof typeof ENTITY_TYPE] | undefined => {
  switch (typename) {
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_PROFESSIONAL_CERT:
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_SPECIALIZATION:
    case COLLECTION_TYPENAMES.STACKABLE_SPECIALIZATION:
    case ProductCardProductType.Specialization:
    case ProductCardProductType.ProfessionalCertificate:
      return ENTITY_TYPE.S12N;
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_COURSE:
    case COLLECTION_TYPENAMES.COURSES:
    case PROGRAM_CURRICULUM_TYPENAMES.COURSE:
    case ProductCardProductType.Course:
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_GUIDED_PROJECT:
    case PROGRAM_CURRICULUM_TYPENAMES.GUIDED_PROJECT:
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_PROJECT:
    case PROGRAM_CURRICULUM_TYPENAMES.PROJECT:
    case ProductCardProductType.GuidedProject:
    case ProductCardProductType.Project:
      return ENTITY_TYPE.COURSE;
    case COLLECTION_TYPENAMES.DEGREE_LISTS:
    case COLLECTION_TYPENAMES.STACKABLE_DEGREES:
      return ENTITY_TYPE.DEGREE;
    case COLLECTION_TYPENAMES.DEGREE_LIST_MASTERTRACK:
    case ProductCardProductType.Mastertrack:
      return ENTITY_TYPE.MASTERTRACK;
    case COLLECTION_TYPENAMES.DEGREE_LIST_UNIVERSITY_CERTIFICATE:
      return ENTITY_TYPE.CERTIFICATE;
    case COLLECTION_TYPENAMES.CLIPS:
    case ProductCardProductType.Video:
      return ENTITY_TYPE.CLIP;
    default:
      return undefined;
  }
};

export const getProductTypeFromTypename = (
  typename?: string
): (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS] | undefined => {
  switch (typename) {
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_PROFESSIONAL_CERT:
    case ProductCardProductType.ProfessionalCertificate:
      return LEARNING_PRODUCTS.ProfessionalCertificate;
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_SPECIALIZATION:
    case COLLECTION_TYPENAMES.STACKABLE_SPECIALIZATION:
    case ProductCardProductType.Specialization:
      return LEARNING_PRODUCTS.Specialization;
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_GUIDED_PROJECT:
    case PROGRAM_CURRICULUM_TYPENAMES.GUIDED_PROJECT:
    case ProductCardProductType.GuidedProject:
      return LEARNING_PRODUCTS.GuidedProject;
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_PROJECT:
    case PROGRAM_CURRICULUM_TYPENAMES.PROJECT:
    case ProductCardProductType.Project:
      return LEARNING_PRODUCTS.Project;
    case COLLECTION_TYPENAMES.DEGREE_LISTS:
      return LEARNING_PRODUCTS.Degree;
    case COLLECTION_TYPENAMES.STACKABLE_DEGREES:
      return LEARNING_PRODUCTS.Degree;
    case COLLECTION_TYPENAMES.DEGREE_LIST_MASTERTRACK:
    case ProductCardProductType.Mastertrack:
      return LEARNING_PRODUCTS.Mastertrack;
    case COLLECTION_TYPENAMES.DEGREE_LIST_UNIVERSITY_CERTIFICATE:
      return LEARNING_PRODUCTS.UniversityCertificate;
    case COLLECTION_TYPENAMES.CLIPS:
    case ProductCardProductType.Video:
      return LEARNING_PRODUCTS.Video;
    case COLLECTION_TYPENAMES.DISCO_COLLECTIONS_COURSE:
    case COLLECTION_TYPENAMES.COURSES:
    case PROGRAM_CURRICULUM_TYPENAMES.COURSE:
    case ProductCardProductType.Course:
      return LEARNING_PRODUCTS.Course;
    default:
      return undefined;
  }
};
export const getCarouselEntityTypeFromProductCardTypename = (
  typename: ProductCardProductType
): (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS] | undefined => {
  switch (typename) {
    case 'COURSE':
      return LEARNING_PRODUCTS.Course;
    case 'SPECIALIZATION':
      return LEARNING_PRODUCTS.Specialization;
    case 'PROFESSIONAL_CERTIFICATE':
      return LEARNING_PRODUCTS.ProfessionalCertificate;
    case 'MASTERTRACK':
      return LEARNING_PRODUCTS.Mastertrack;
    case 'GUIDED_PROJECT':
      return LEARNING_PRODUCTS.GuidedProject;
    case 'PROJECT':
      return LEARNING_PRODUCTS.Project;
    case 'VIDEO':
      return LEARNING_PRODUCTS.Video;
    default:
      return undefined;
  }
};

export const getEntityTypeFromVariant = (productVariant?: string | null) => {
  if (!productVariant) return undefined;

  switch (productVariant) {
    case PREMIUM_PRODUCT_TYPENAMES.BACHELORS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES.MASTERS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES.POSTGRADUATE_DIPLOMA:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.BACHELORS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.MASTERS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.POSTGRADUATE_DIPLOMA:
      return ENTITY_TYPE.DEGREE;
    case PREMIUM_PRODUCT_TYPENAMES.MASTERTRACK:
      return ENTITY_TYPE.MASTERTRACK;
    case PREMIUM_PRODUCT_TYPENAMES.UNIVERSITY_CERTIFICATE:
      return ENTITY_TYPE.CERTIFICATE;
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.PROFESSIONAL_CERTIFICATE_S12N:
    case NormalS12n:
      return ENTITY_TYPE.S12N;
    default:
      return undefined;
  }
};

export const getProductTypeFromVariant = (productVariant?: string | null) => {
  if (!productVariant) return undefined;

  switch (productVariant) {
    case PREMIUM_PRODUCT_TYPENAMES.BACHELORS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES.MASTERS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES.POSTGRADUATE_DIPLOMA:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.BACHELORS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.MASTERS_DEGREE:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.POSTGRADUATE_DIPLOMA:
      return LEARNING_PRODUCTS.Degree;
    case PREMIUM_PRODUCT_TYPENAMES.MASTERTRACK:
      return LEARNING_PRODUCTS.Mastertrack;
    case PREMIUM_PRODUCT_TYPENAMES.UNIVERSITY_CERTIFICATE:
      return LEARNING_PRODUCTS.UniversityCertificate;
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
    case PREMIUM_PRODUCT_TYPENAMES_CORE_DEGREE.PROFESSIONAL_CERTIFICATE_S12N:
      return LEARNING_PRODUCTS.ProfessionalCertificate;
    case NormalS12n:
    default:
      return LEARNING_PRODUCTS.Specialization;
  }
};

export const getLinkFromProductVariant = (slug: string, typeName: string, productVariant: string | null) => {
  if (
    !productVariant &&
    (typeName === COLLECTION_TYPENAMES.DISCO_COLLECTIONS_GUIDED_PROJECT ||
      typeName === COLLECTION_TYPENAMES.DISCO_COLLECTIONS_PROJECT)
  ) {
    return `/projects/${slug}`;
  } else if (!productVariant) {
    return `/learn/${slug}`;
  }

  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
      return `/${ROUTE_CONFIG.PROFESSIONAL_CERTIFICATES.pathname}/${slug}`;
    case NormalS12n:
    default:
      return `/${ROUTE_CONFIG.SPECIALIZATIONS.pathname}/${slug}`;
  }
};

export const getProgramLinkFromProductType = (
  programSlug: string,
  collectionId?: string | null,
  slug?: string,
  productType?: string
) => {
  if (!slug || !productType) return `/programs/${programSlug}`;

  let programLink = '';
  const searchParams = new URLSearchParams(collectionId ? { collectionId } : {});
  switch (productType) {
    case LEARNING_PRODUCTS.Specialization:
      programLink = `/programs/${programSlug}/${ROUTE_CONFIG.SPECIALIZATIONS.pathname}/${slug}`;
      break;
    case LEARNING_PRODUCTS.ProfessionalCertificate:
      programLink = `/programs/${programSlug}/${ROUTE_CONFIG.PROFESSIONAL_CERTIFICATES.pathname}/${slug}`;
      break;
    case LEARNING_PRODUCTS.Course:
      programLink = `/programs/${programSlug}/${ROUTE_CONFIG.COURSE.pathname}/${slug}`;
      break;
    case LEARNING_PRODUCTS.Project:
    case LEARNING_PRODUCTS.GuidedProject:
      programLink = `/programs/${programSlug}/${ROUTE_CONFIG.PROJECTS.pathname}/${slug}`;
      break;
    case LEARNING_PRODUCTS.Video:
      programLink = `/videos/${slug}`;
      searchParams.append('program', programSlug);
      break;
    default:
      programLink = `/programs/${programSlug}`;
      break;
  }

  return `${programLink}?${searchParams.toString()}`;
};

export const getLinkFromCarouselTypeName = (
  slug: string,
  carouselType?: (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]
) => {
  switch (carouselType) {
    case LEARNING_PRODUCTS.Course:
      return `/learn/${slug}`;
    case LEARNING_PRODUCTS.GuidedProject:
    case LEARNING_PRODUCTS.Project:
      return `/projects/${slug}`;
    case LEARNING_PRODUCTS.Specialization:
      return `/specializations/${slug}`;
    case LEARNING_PRODUCTS.ProfessionalCertificate:
      return `/professional-certificates/${slug}`;
    default:
      return `/learn/${slug}`;
  }
};

export const carouselExperimentLocations = ['/', '/browse'];

export const CAROUSEL_TYPES = [
  'skill_based_personalized',
  'semi_personalized',
  'reconfigurable_components',
  'fully_personalized',
  'curated',
  'algolia_based',
];

export const MAX_ITEMS_PER_ENTERPRISE_CAROUSEL = 24;
