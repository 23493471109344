/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { IconSize } from 'bundles/ai-coach-platform/types';

type Props = {
  size?: IconSize;
  fill?: string;
};

/**
 * MUI icon for stop filled
 */
const StopIconFilled = ({ size = 16, fill = 'var(--cds-color-grey-950)' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
    <path
      d="M6 16V8C6 7.45 6.19583 6.97917 6.5875 6.5875C6.97917 6.19583 7.45 6 8 6H16C16.55 6 17.0208 6.19583 17.4125 6.5875C17.8042 6.97917 18 7.45 18 8V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H8C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16Z"
      fill={fill}
    />
  </svg>
);

export default StopIconFilled;
