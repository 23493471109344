import type React from 'react';
import { useState, useEffect, forwardRef } from 'react';

import { Popover as MuiPopover } from '@material-ui/core';

import clsx from 'clsx';

import { useLocale, useLocalizedStringFormatter } from '@coursera/cds-common';
import { CloseIcon } from '@coursera/cds-icons';

import i18nMessages from '@core/forms/i18n';
import IconButtonBase from '@core/IconButton/IconButtonBase';
import { SlideUp } from '@core/transitions/SlideUp';

import type { Props as ContainerProps } from './Container';
import Container from './Container';
import getDrawerCss, { classes } from './styles/drawerCss';
import type { BasePopoverProps } from './types';

export type Props = {
  /**
   * Reference to a focusable element that should receive initial focus when the
   * component opens. By default, the first focusable element inside the component
   * will receive focus.
   */
  initialFocusRef?: ContainerProps['initialFocusRef'];

  /**
   * Reference to a focusable element that should receive focus when the component
   * closes. By default, focus will return to the element that had focus when the
   * component was opened.
   */
  returnFocusRef?: ContainerProps['returnFocusRef'];

  /**
   * Determines whether first interactive element has to be focused when dropdown opens.
   * If set to `false` anything will be focused automatically.
   * @default true
   */
  autoFocus?: ContainerProps['autoFocus'];

  /**
   * If set to `true` drawer will always render at full height of the screen.
   */
  fullHeight?: boolean;

  /**
   * When set to `false` ths close button will not be rendered.
   * Please note, there should be always alternative way of closing the drawer.
   */
  hideCloseButton?: boolean;

  /**
   * Allows to set fixed height of the drawer.
   */
  height?: string;
} & BasePopoverProps;

/**
 * Drawer components are used to display a temporary sheet of material that slides in from the bottom edge of the screen.
 */
const Drawer = forwardRef<HTMLElement, Props>(function _Drawer(props, ref) {
  const {
    children,
    initialFocusRef,
    returnFocusRef,
    hideCloseButton,
    autoFocus,
    className,
    fullHeight,
    height = 'auto',
    container,
    open,
    ...popoverProps
  } = props;

  const css = getDrawerCss({ height });

  const { direction } = useLocale();

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const [footerOffset, setFooterOffset] = useState('100%');

  /**
   * Handles onClose event
   * @param event
   */
  const handleClose = (event: React.ChangeEvent<unknown>) => {
    popoverProps.onClose?.(event, 'backdropClick');
  };

  const handleWindowResize = () => {
    setFooterOffset(`${window?.visualViewport?.height}px`);
  };

  // Adjust footer position when virtual keyboard is opened
  useEffect(() => {
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleWindowResize);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleWindowResize);
      }
    };
  }, []);

  // Apply overflow hidden to the body when drawer is opened even when container is different than body
  // Related to the combobox workaround
  useEffect(() => {
    if (open && container && container !== document.body) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (container && container !== document.body) {
        document.body.style.removeProperty('overflow');
      }
    };
  }, [container, open]);

  return (
    <MuiPopover
      ref={ref}
      classes={{
        ...popoverProps.classes,
        paper: clsx(classes.paper, popoverProps.classes?.paper),
      }}
      css={css}
      style={{
        // @ts-expect-error -- define css variable
        '--drawer-footer-offset': footerOffset,
      }}
      {...popoverProps}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      TransitionComponent={SlideUp}
      anchorEl={null}
      anchorReference="none"
      className={clsx(className, {
        [classes.fullHeight]: fullHeight,
      })}
      container={container}
      dir={direction}
      open={open}
    >
      <Container
        autoFocus={autoFocus}
        initialFocusRef={initialFocusRef}
        layout="drawer"
        returnFocusRef={returnFocusRef}
        onClose={handleClose}
      >
        {!hideCloseButton && (
          <div className={classes.topBar}>
            <IconButtonBase
              aria-label={stringFormatter.format('close')}
              size="small"
              variant="ghost"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButtonBase>
          </div>
        )}
        {children}
      </Container>
    </MuiPopover>
  );
});

export default Drawer;
