import * as React from 'react';
import { useState } from 'react';

import markdownIt from 'markdown-it';

import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { VisuallyHidden } from '@coursera/cds-core';

import CoachIconButton from 'bundles/ai-coach-platform/components/building-blocks/CoachIconButton';
import { CopyIcon } from 'bundles/ai-coach-platform/components/icons/mui';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';
import { useTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  /**
   * the message to be copied to clipboard
   */
  message: string;

  /**
   * Optional tooltip text for Copy button
   * @default 'Copy'
   */
  tooltip?: string;

  /**
   * associated metadata for the copied content
   */
  metadata?: {
    name?: string;
  };

  /**
   * Callback fired after copied to clipboard successfully
   */
  onCopy?: () => void;

  /**
   * skip adding metadata to the final clipboard data
   */
  skipMetadata?: boolean;
};

/**
 * returns the Markdown template for the final content (response + metadata) to be copied to clipboard
 */
const copyTemplate = (props: Pick<Props, 'message' | 'metadata'>) => {
  const { metadata, message } = props;

  // fallback to page title without the " | Coursera" suffix
  const metadataName = metadata?.name ?? document.title.split(' |')[0];
  // track links for usage metrics
  const link = `${location.href.split('?')[0]}?trk_ref=coach_copy`;
  const markdownLink = `[${metadataName}](${link})`;
  const formattedDate = formatDateTimeDisplay(new Date(), MED_DATE_ONLY_DISPLAY);

  return `${markdownLink}&nbsp;&nbsp;${formattedDate}\n\n${message}`;
};

/**
 * Coach icon button to copy response message to clipboard,
 * see https://coursera.atlassian.net/browse/COACH-186.
 */
export const CopySingleResponse = (props: Props): React.ReactElement => {
  const { message, onCopy, metadata, tooltip = _t('Copy'), skipMetadata = false } = props;

  const [copied, setCopied] = useState<boolean>(false);

  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;

  const onCopySuccess = () => {
    onCopy?.();
    setCopied(true);

    // reset state after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };

  const onCopyFail = (error: Error) => {
    captureMessageInSentry({ error, source: 'onCopyFail', extra: { message: 'Error copying response to clipboard' } });
  };

  const handleCopy = () => {
    if (typeof window !== 'undefined') {
      if (trackingContext) {
        // v3 tracking
        track('interact_coach', {
          coachInteractionType: 'copy_coach_message',
          coach: {
            mode: trackingContext.mode,
          },
          ...(trackingContext.courseId && {
            product: {
              type: 'course',
              id: trackingContext.courseId as string,
            },
          }),
          ...(trackingContext.itemId && {
            courseItem: {
              id: trackingContext.itemId as string,
            },
          }),
        });
      }

      const copyData = skipMetadata ? message : copyTemplate({ message, metadata });
      // use rich-text clipboard API when supported
      if (typeof ClipboardItem !== 'undefined') {
        // convert markdown to html, same as we do for Message.tsx
        const htmlContent = new Blob([markdownIt({ breaks: true }).render(copyData)], { type: 'text/html' });
        // fallback text content
        const textContent = new Blob([message], { type: 'text/plain' });
        const clipboardData = new ClipboardItem({ 'text/html': htmlContent, 'text/plain': textContent });
        navigator.clipboard.write([clipboardData]).then(onCopySuccess).catch(onCopyFail);
      } else {
        // fallback to plain-text otherwise (e.g. Firefox)
        navigator.clipboard.writeText(copyData).then(onCopySuccess).catch(onCopyFail);
      }
    }
  };

  return (
    <div className="coach-copy-single-response">
      <CoachIconButton
        size="small"
        aria-label={_t('Copy this response to clipboard')}
        icon={<CopyIcon filled={copied} />}
        tooltip={copied ? _t('Copied!') : tooltip}
        onClick={handleCopy}
        variant="ghost"
      />
      <VisuallyHidden aria-live="assertive">{copied ? _t('Copied response to clipboard') : ''}</VisuallyHidden>
    </div>
  );
};

export default CopySingleResponse;
