export default {
  s: '%d секунд',
  smicro: '%d с',
  smid: '%d сек',

  ss: '%d секунд',
  ssmicro: '%d с',
  ssmid: '%d сек',

  m: '%d минут',
  mmicro: '%d м',
  mmid: '%d мин',

  mm: '%d минут',
  mmmicro: '%d мин',
  mmmid: '%d мин',

  h: '%d сағат',
  hmicro: '%d сағ',
  hmid: '%d сағ',

  hh: '%d сағат',
  hhmicro: '%d С',
  hhmid: '%d сағат',

  d: '%d күн',
  dmicro: '%d к',
  dmid: '%d күн',

  dd: '%d күн',
  ddmicro: '%d к',
  ddmid: '%d күн',
};
