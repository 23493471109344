import { css } from '@emotion/react';

import { typography } from '@core/Typography2';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('notificationBadge', [
  'content',
  'inline',
  'top',
  'default',
  'invert',
]);

export default () => {
  return css`
    display: inline-flex;
    flex-shrink: 0;
    flex-direction: row;

    .${classes.content} {
      height: 20px;
      min-width: 20px;
      max-width: 42px;
      padding: 0 6px;
      border-radius: var(--cds-border-radius-200);
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;

      ${typography.subtitleMedium}
    }

    .${classes.default} {
      background-color: var(--cds-color-feedback-error);
      color: var(--cds-color-neutral-primary-invert);
    }

    .${classes.invert} {
      background-color: var(--cds-color-neutral-background-primary);
      color: var(--cds-color-neutral-primary);
    }

    .${classes.inline} {
      margin-left: var(--cds-spacing-100);
      align-self: center;
    }

    .${classes.top} {
      margin-left: -10px;
      transform: translateY(-50%);
      align-self: flex-start;
    }
  `;
};
