import * as React from 'react';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import type { AutocompleteMode } from 'bundles/search-common/constants/autocomplete';
import { type AutocompleteItems, getAutocompleteEventingData } from 'bundles/search-common/utils/autocomplete';

const AutocompleteOptionTrackingDiv = ({
  children,
  autocompleteState,
  id,
  recentlyViewedItems,
  suggestionIndex,
}: {
  children: React.ReactNode;
  autocompleteState: AutocompleteMode;
  id: string;
  recentlyViewedItems: AutocompleteItems;
  suggestionIndex?: number;
}) => {
  const trackingData = getAutocompleteEventingData(id, autocompleteState, recentlyViewedItems, suggestionIndex);
  const trackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_autocomplete_suggestions',
    trackingData,
    [],
    { fullyVisible: true, initialDelay: 1000 }
  );
  return (
    <div ref={trackingRef}>
      <TrackedDiv withVisibilityTracking requireFullyVisible trackingName="autocomplete_item">
        {children}
      </TrackedDiv>
    </div>
  );
};
export default AutocompleteOptionTrackingDiv;
