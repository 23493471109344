/** @jsx jsx */
// do not remove this import: `css` is needed to set `newFeatureCSS` below
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import { isCourserian } from 'js/lib/user';

import { type IconButtonProps } from '@coursera/cds-core';
import type { CourseItem, Product } from '@coursera/event-pulse-types';

import { CoachChatLauncher } from 'bundles/ai-coach-platform/components/building-blocks';
import { useNewFeatureStyles } from 'bundles/ai-course-coach/utils/newFeaturesUtils';
import { useCoachTrackingContext } from 'bundles/ai-course-coach/utils/tracking';
import { useCourseToolBarPlugins } from 'bundles/course-v2/components/navigation/CourseToolBar/useCourseToolBarPlugins';
// loading directly to avoid lazy loading complexity
import leeCoachEpicClient from 'bundles/epic/clients/leeCoach';
import { useIsComposablePageHeader } from 'bundles/page-header/contexts/PageHeaderContext';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/learning-assistant-chat-ai';

const LoadableCoachStudio = createLoadableComponent(() => import('bundles/ai-coach-studio/index'));

const styles = {
  container: css`
    margin-right: var(--cds-spacing-100);
  `,
};

type Props = {
  size?: IconButtonProps['size'];
};

const ExternalCoachLauncher = (props: Props) => {
  const isComposablePageHeader = useIsComposablePageHeader();
  const [coachEnabled, setEnabled] = useState(false);
  const { plugins, toolSelected, setToolSelected, expanded, setExpanded } = useCourseToolBarPlugins();
  const [showCoachStudio, setShowCoachStudio] = useState(false);

  const [shouldShowFeatureCSS, setShowFeatureCSS] = useState<boolean>(false);

  const { newFeatureCSS, newFeatureInteractedCallback } = useNewFeatureStyles({
    feature: 'ExternalCoachLauncher',
    animation: 'bounce',
  });

  const { size = 'medium' } = props;

  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;

  const sharedEventingData = useMemo(
    () => ({
      product: {
        type: 'course',
        id: trackingContext?.courseId,
      } as Product,
      courseItem: {
        id: trackingContext?.itemId,
      } as CourseItem,
    }),
    [trackingContext]
  );

  const launcherVisibilityTrackingRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_coach',
    {
      coachViewType: 'view_coach_tool_launcher',
      coach: {
        mode: trackingContext?.mode,
        location: 'toolbar',
      },
      ...(trackingContext?.courseId && { product: sharedEventingData.product }),
      ...(trackingContext?.itemId && { courseItem: sharedEventingData.courseItem }),
    }
  );

  useEffect(() => {
    const handleChatClose = () => {
      setShowFeatureCSS(true);
    };

    if (expanded) {
      // reset when expanded for next time
      setShowFeatureCSS(false);
    }

    if (coachEnabled) {
      document.body.addEventListener('coachChatClosed', handleChatClose);
    }

    return () => {
      document.body.removeEventListener('coachChatClosed', handleChatClose);
    };
  }, [coachEnabled, expanded, launcherVisibilityTrackingRef]);

  // if coach plugin registered enable coach
  useEffect(() => {
    if (plugins === null) {
      return;
    }
    const coachPlugin = plugins.find((plugin) => plugin.name === 'coach');
    if (coachPlugin) {
      setEnabled(true);
      setShowCoachStudio(false);
    } else {
      setEnabled(false);
      setShowCoachStudio(leeCoachEpicClient.get('coachStudioEnabled'));
      // check this if embedded coach is not enabled
    }
  }, [plugins]);

  const handleClick = () => {
    newFeatureInteractedCallback();

    if (toolSelected === 'coach') {
      setExpanded(!expanded);
    } else {
      setToolSelected('coach');
      setExpanded(true);
    }

    track('interact_coach', {
      coachInteractionType: expanded ? 'close_coach_tool' : 'launch_coach_tool',
      coach: {
        mode: trackingContext?.mode,
        location: 'toolbar',
      },
      ...(trackingContext?.courseId && { product: sharedEventingData.product }),
      ...(trackingContext?.itemId && { courseItem: sharedEventingData.courseItem }),
    });
  };

  const isSelected = coachEnabled && expanded;
  const isCoachStudioEnabled = !coachEnabled && showCoachStudio && isCourserian();
  return isCoachStudioEnabled ? (
    <LoadableCoachStudio />
  ) : (
    <div
      ref={launcherVisibilityTrackingRef}
      css={[!isComposablePageHeader && styles.container, shouldShowFeatureCSS && newFeatureCSS]}
    >
      <CoachChatLauncher onClick={handleClick} defaultSelected={isSelected} disabled={!coachEnabled} size={size} />
    </div>
  );
};

export default ExternalCoachLauncher;
