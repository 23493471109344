// =====================================================================================================================
// Looking for a way to rebuild all of the apps? You want the adjacent appStarterFactoryForceBuild.ts, which does not
// have a CODEOWNER and therefore won't pull in any additional code reviews that could delay merging your pull requests.
//
// You can find that file here: static/bundles/page/lib/appStarterFactoryForceBuild.ts
//
// If you instead change this file, your pull request will require a review from @webedx-spark/web-architecture-group,
// which may not happen promptly.
// =====================================================================================================================
import * as React from 'react';
import ReactDOM from 'react-dom';
import type { PlainRoute, RouterState } from 'react-router';
import { Router } from 'react-router';

import log from 'js/app/loggerSingleton';
import 'js/app/setupEnvironment';
import setupMoment from 'js/app/setupMoment';
import getLocale from 'js/lib/locale.client';
import timing from 'js/lib/timing';
import { OldRouterNewContext } from 'js/lib/useRouter';

import { StyleSheet } from '@coursera/aphrodite';

import ApplicationActionCreators from 'bundles/page/actions/ApplicationActionCreators';
import MetatagsWrapper from 'bundles/page/components/MetatagsWrapper';
import { createApolloClient } from 'bundles/page/lib/network/Apollo';
import { provideAppWithContext as defaultProvideAppWithContext } from 'bundles/page/lib/reactApps';
import { setupClient as defaultSetupClient } from 'bundles/page/lib/router';
import { notifyConsole } from 'bundles/page/utils/peekers';

import './appStarterFactoryForceBuild';

const ssrStage = (stage: string) => `serverRendering.${stage}`;

const MOUNT_NODE = document.getElementById('rendered-content');

const rehydrateFluxible = (appEnv: $TSFixMe, dehydratedState: $TSFixMe) => {
  log.info('Rendering by rehydrating dehydratedState');
  timing.setMark(ssrStage('startRehydrate'));
  timing.time('fluxibleRehydrate');

  return new Promise((resolve, reject) => {
    appEnv.rehydrate(dehydratedState, (err: $TSFixMe, fluxibleContext: $TSFixMe) => {
      if (err) return reject(err);

      // Used in bundles/page/lib/Timing
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'context' does not exist on type 'Window ... Remove this comment to see the full error message
      window.context = fluxibleContext;

      timing.timeEnd('fluxibleRehydrate');
      return resolve(fluxibleContext);
    });
  });
};

declare global {
  interface Window {
    /** Used by E2E test runner to wait for initial render. */
    REACT_RENDERED?: boolean;
  }
}

const renderReact = (type: $TSFixMe) => {
  const renderedEvent = new CustomEvent('rendered');

  timing.time('stylesheetRehydrate');
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'renderedClassNames' does not exist on ty... Remove this comment to see the full error message
  StyleSheet.rehydrate(window.renderedClassNames);
  timing.timeEnd('stylesheetRehydrate');

  timing.time('reactRender');
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ssr' does not exist on type 'Window & ty... Remove this comment to see the full error message
  const renderToDOM = window.ssr ? ReactDOM.hydrate : ReactDOM.render;
  renderToDOM(React.createElement(type), MOUNT_NODE, () => {
    // Track if SSR matches CSR. SSR and CSR matches when 'data-react-checksum' exists in the DOM
    const wrappers = document.getElementsByClassName('rc-MetatagsWrapper');
    let checksum;
    Array.prototype.forEach.call(wrappers, (wrapper) => {
      checksum = wrapper.getAttribute('data-react-checksum');
    });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'REACT_CHECKSUM' does not exist on type '... Remove this comment to see the full error message
    window.REACT_CHECKSUM = checksum; // TODO: remove when on React 16
    window.REACT_RENDERED = true;

    log.info('React app rendered');
    timing.setMark(ssrStage('completeRender'), true);
    timing.timeEnd('reactRender');
    window.dispatchEvent(renderedEvent);
  });
};

// Remove SSR-injected styles that are duplicated during CSR.
const removeDuplicatedSsrStyles = () => {
  const cdsStyles = document.querySelector('style[data-coursera-design-system]');
  if (cdsStyles) {
    cdsStyles.parentElement?.removeChild(cdsStyles);
  }
};

// Takes in an array of matched react-routes and returns a compound matched route string.
// eg.
// [{ path: '/degrees/:slug' }, { path: 'overview' }]
// > '/degrees/:slug/overview'
const mergeMatchedPaths = (routes: PlainRoute[]): string => {
  const matchedRoute = routes.reduce((acc, { path }, idx) => {
    if (typeof path === 'undefined') {
      return acc;
    }

    const leadingSlash = acc.charAt(acc.length - 1) !== '/' && idx !== 0 ? '/' : '';

    return acc + `${leadingSlash}${path}`;
  }, '');

  return `${matchedRoute.charAt(0) !== '/' ? '/' : ''}${matchedRoute}`;
};

const appStarterFactory = (fluxibleAppEnv: $TSFixMe) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'App' does not exist on type 'Window & ty... Remove this comment to see the full error message
  const { fluxibleData = window.App } = {};
  const overrideCb: $TSFixMe = undefined;

  // Interop with ES modules
  if (fluxibleAppEnv.default) {
    fluxibleAppEnv = fluxibleAppEnv.default;
  }

  // Interop with factory functions (to get away from singletons)
  if (typeof fluxibleAppEnv === 'function') {
    fluxibleAppEnv = fluxibleAppEnv();
  }

  // Optional function overrides
  const { setupClient = defaultSetupClient, provideAppWithContext = defaultProvideAppWithContext } = overrideCb
    ? overrideCb()
    : {};

  // TODO(jon): find a less fragile abstraction for skipping hydration.
  if (fluxibleData === 'SKIP_CLIENT') {
    return null;
  }

  setupMoment();

  // Let devs who are peeking under the hood know we are hiring!
  notifyConsole();

  const initializeAppEnvironment = () => {
    timing.time('initializeAppEnvironment');
    return Promise.all([
      fluxibleData ? rehydrateFluxible(fluxibleAppEnv, fluxibleData) : fluxibleAppEnv.createContext(),
      createApolloClient(),
    ]).then((values) => {
      timing.timeEnd('initializeAppEnvironment');
      return values;
    });
  };

  // TODO refactor during react-intl v2 migration
  const reactIntlContext = {
    locale: getLocale(),
    // note: [FLEX-19325] temporary fix does not pass messages property which will be required by react-intl v2 migration
  };

  const promise = initializeAppEnvironment()
    .then(([fluxibleContext, apolloClient]) => {
      const routerConfiguration = fluxibleAppEnv.getComponent();

      timing.time('setupRoutes');
      return setupClient({ routes: routerConfiguration, apolloClient })
        .then((renderProps: RouterState) => {
          timing.timeEnd('setupRoutes');
          timing.setMark(ssrStage('startFresh'));
          return (
            fluxibleContext
              // TODO: Deprecate setUserAgent? (do we need this?)
              .executeAction(ApplicationActionCreators.setUserAgent, navigator.userAgent)
              .then(() => renderProps)
          );
        })
        .then((renderProps: $TSFixMe) => {
          const { history, location, router } = renderProps;
          const routerContextValue = { history, location, router };
          return provideAppWithContext({
            routing: () => (
              <MetatagsWrapper url={window.location.href}>
                <OldRouterNewContext.Provider value={routerContextValue}>
                  <Router {...renderProps} />
                </OldRouterNewContext.Provider>
              </MetatagsWrapper>
            ),
            fluxibleContext,
            apolloClient,
            reactIntlContext,
          });
        })
        .then((type: React.ComponentType) => {
          renderReact(type);
          removeDuplicatedSsrStyles();
        });
    })
    .catch((err: $TSFixMe) => {
      throw err;
    });

  return promise;
};

export default appStarterFactory;
