/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import cx from 'classnames';

import { isRightToLeft } from 'js/lib/language';

import { Button } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import _t from 'i18n!nls/ai-coach-platform';

export type Props = ButtonProps & { selected?: boolean };

const styles = {
  coachButton: ({ size, isRTL }: { size: 'small' | 'medium'; isRTL: boolean }) => css`
    /* Custom gradients */
    --default-gradient: linear-gradient(
      279deg,
      var(--cds-color-emphasis-primary-content-default) -5.78%,
      var(--cds-color-blue-600) 83.48%
    );
    --interacted-gradient: linear-gradient(
      310deg,
      var(--cds-color-emphasis-primary-content-default) -10.46%,
      var(--cds-color-blue-600) 78.54%,
      #66dab7 116.68%
    );

    /* CSS overrides */
    --cds-color-neutral-background-primary: var(--cds-color-white-0);
    --cds-color-neutral-disabled: var(--cds-color-grey-100);
    --cds-color-neutral-disabled-strong: var(--cds-color-grey-100);
    --cds-color-neutral-disabled-weak: var(--cds-color-white-0);
    --cds-color-interactive-primary-selected: var(--cds-color-emphasis-primary-background-xweak);
    --cds-color-interactive-stroke-primary-focus: var(--cds-color-emphasis-primary-background-xxstrong);
    --cds-color-interactive-stroke-primary-focus-invert: var(--cds-color-emphasis-primary-background-weak);

    border-radius: var(--cds-border-radius-100);
    background: ${isRTL ? 'var(--cds-color-blue-600)' : 'var(--default-gradient)'};
    box-shadow: none;
    padding: ${size === 'medium'
      ? 'var(--cds-spacing-150) var(--cds-spacing-200)'
      : 'var(--cds-spacing-100) var(--cds-spacing-200)'};

    &:hover,
    &:active,
    &.selected {
      background: ${isRTL ? 'var(--cds-color-emphasis-primary-content-default)' : 'var(--interacted-gradient)'};
    }

    &.cds-button-focusVisible {
      background: ${isRTL ? 'var(--cds-color-emphasis-primary-content-default)' : 'var(--interacted-gradient)'};

      &::after {
        border-radius: 10px !important;
      }
    }

    &:active {
      border-radius: 12px;
      transition: all 0.2s cubic-bezier(0, 1, 0.9, 1);
      transform: scale3d(0.98, 0.98, 0.98);
    }

    &[aria-disabled='true'] {
      background: var(--cds-color-neutral-disabled);

      .cds-button-label {
        color: var(--cds-color-neutral-disabled-weak);
      }
    }

    &.coach-secondary-button {
      position: relative;
      z-index: 1;

      .cds-button-label {
        color: var(--cds-color-emphasis-neutral-background-xxstrong);
      }

      &::before {
        content: '';
        position: absolute;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        border-radius: 6px;
        background-color: var(--cds-color-neutral-background-primary);
        z-index: -1;
      }

      &:active {
        &::before {
          border-radius: 10px;
        }
      }

      &.selected {
        &::before {
          background-color: var(--cds-color-interactive-primary-selected);
        }

        svg:not(.sparkle-icon) path {
          fill: var(--cds-color-blue-600);
        }
      }

      &:hover,
      &.cds-button-focusVisible,
      &.selected {
        box-shadow: none;

        .cds-button-label {
          color: var(--cds-color-blue-600);
        }

        svg:not(.sparkle-icon) path {
          fill: var(--cds-color-blue-600);
        }
      }

      &[aria-disabled='true'] {
        .cds-button-label {
          color: var(--cds-color-neutral-disabled);
        }

        svg path {
          fill: var(--cds-color-neutral-disabled);
        }
      }
    }
  `,
  ghostButton: css`
    background: transparent;

    .cds-button-label {
      color: var(--cds-color-emphasis-neutral-background-xxstrong);
    }

    &:hover,
    &:active,
    &.selected,
    &.cds-button-focusVisible {
      background: var(--cds-color-emphasis-primary-background-xweak);
      text-decoration: none;
    }

    &.selected {
      .cds-button-label {
        color: var(--cds-color-blue-600);
      }

      svg path {
        fill: var(--cds-color-blue-600);
      }
    }

    &.cds-button-disabled {
      background: transparent;

      .cds-button-label {
        color: var(--cds-color-neutral-disabled);
      }

      svg path {
        fill: var(--cds-color-neutral-disabled);
      }
    }
  `,
};

/**
 * Simple wrapper around CDS Button to provide Coach-specific styles.
 */
export const CoachButton = React.forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const { children, size = 'medium', variant, className, selected, ...rest } = props;

  // eslint-disable-next-line import/no-named-as-default-member
  const isRTL = isRightToLeft(_t.getLocale());

  if (variant === 'secondary') {
    return (
      <Button
        ref={ref}
        variant="secondary"
        className={cx('coach-secondary-button', className, { selected })}
        textAlign="start"
        size={size}
        css={styles.coachButton({ size, isRTL })}
        {...rest}
      >
        {children}
      </Button>
    );
  } else if (variant === 'ghost') {
    return (
      <Button
        ref={ref}
        variant="ghost"
        className={cx(className, { selected })}
        textAlign="start"
        size={size}
        css={[styles.coachButton({ size, isRTL }), styles.ghostButton]}
        {...rest}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <Button
        ref={ref}
        className={cx(className, { selected })}
        textAlign="start"
        size={size}
        css={styles.coachButton({ size, isRTL })}
        {...rest}
      >
        {children}
      </Button>
    );
  }
});

export default CoachButton;
