import React from 'react';

import type { IconProps } from '@common/components/SvgIcon';
import { SvgIcon } from '@common/components/SvgIcon';
import useLocale from '@common/i18n/useLocale';
import useId from '@common/utils/useId';

export type SVGPaths =
  | React.ReactFragment
  | ((props: { iconColor?: string; id?: string }) => React.ReactFragment);

type SvgPathsSizeMap = { large: SVGPaths };

export type SvgPathsMap = {
  ltr: SvgPathsSizeMap;
  rtl?: SvgPathsSizeMap;
};

/**
 * Private module reserved for unit testing.
 * Named uppercase to signal this is a React Component. See https://reactjs.org/docs/hooks-rules.html
 */
export const SvgIconWithPaths = (
  props: IconProps,
  ref: React.Ref<SVGSVGElement>,
  paths: SvgPathsMap,
  shouldFlipForRTL?: boolean
): React.ReactElement => {
  const id = useId(props.id);
  const { direction } = useLocale();

  let pathsForDirection = paths.ltr;

  if (direction === 'rtl' && paths.rtl) {
    pathsForDirection = paths.rtl;
  }

  const pathsForSize = pathsForDirection['large'];

  return (
    <SvgIcon ref={ref} shouldFlipForRTL={shouldFlipForRTL} {...props} id={id}>
      {typeof pathsForSize === 'function'
        ? pathsForSize({ id: id })
        : pathsForSize}
    </SvgIcon>
  );
};

/**
 * Creates an icon component using only the 'large' paths.
 * @param displayName
 * @param paths
 * @param shouldFlipForRTL
 */
const createSvgIcon = (
  displayName: string,
  paths: SvgPathsMap,
  shouldFlipForRTL?: boolean
): React.ComponentType<IconProps> => {
  const Component = (props: IconProps, ref: React.Ref<SVGSVGElement>) =>
    SvgIconWithPaths(props, ref, paths, shouldFlipForRTL);
  Component.displayName = displayName;
  return React.forwardRef(Component);
};

export default createSvgIcon;
