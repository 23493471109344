/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import type { FooterLink } from 'bundles/page-footer/components/global-footer/types';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

import 'css!bundles/page-footer/components/global-footer/common/__styles__/LinkColumn';

export type ColumnHeaderProps = {
  columnHeader?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  extraClassName?: string;
};

const styles = {
  listItem: css`
    min-height: 24px;
    min-width: 24px;
  `,
};

type Props = {
  header: string;
  links: Array<FooterLink>;
  boldLinks?: Array<FooterLink>;
  isMobileColumn?: boolean;
} & ColumnHeaderProps;

type LinkListProps = {
  links: Array<FooterLink>;
  boldList?: boolean;
  extraClassName?: string;
};

const LinkList = ({ links, boldList, extraClassName = '' }: LinkListProps) => {
  const listClasses = classNames('rc-GlobalFooter_column_list', {
    'rc-GlobalFooter_column_list--bold': boldList,
  });
  const listItemClasses = classNames('rc-GlobalFooter_column_list_item', extraClassName);
  const listItemLinkClasses = classNames('rc-GlobalFooter_column_list_item_link');

  return (
    <ul className={listClasses} role="list">
      {links
        .filter((link) => !!link)
        .map((link) => (
          <li className={listItemClasses} key={link.label} role="listitem" css={styles.listItem}>
            <TrackedLink2
              className={listItemLinkClasses}
              trackingName={`column_link_${link.label.split(' ').join('_').toLowerCase()}`}
              href={link.href}
            >
              {link.label}
            </TrackedLink2>
          </li>
        ))}
    </ul>
  );
};

export const ColumnHeader: React.LegacyFunctionComponentWithChildren<ColumnHeaderProps> = ({
  columnHeader = 'h3',
  extraClassName = '',
  children,
}) => {
  const headerClasses = classNames('rc-GlobalFooter_column_header', extraClassName);
  switch (columnHeader) {
    case 'h2':
      return <h2 className={headerClasses}>{children}</h2>;
    case 'h3':
      return <h3 className={headerClasses}>{children}</h3>;
    case 'h4':
      return <h4 className={headerClasses}>{children}</h4>;
    case 'h5':
      return <h5 className={headerClasses}>{children}</h5>;
    case 'h6':
      return <h6 className={headerClasses}>{children}</h6>;
    default:
      return <h5 className={headerClasses}>{children}</h5>;
  }
};

export default function LinkColumn({ header, links, boldLinks, isMobileColumn, columnHeader, extraClassName }: Props) {
  if (Array.isArray(links) === false) return null;

  const columnClasses = classNames('rc-GlobalFooter_column', {
    'rc-GlobalFooter_column--mobile-column': isMobileColumn,
  });

  return (
    <div className={columnClasses}>
      <div className="rc-GlobalFooter_column_container">
        <ColumnHeader columnHeader={columnHeader} extraClassName={extraClassName}>
          {header}
        </ColumnHeader>
        <div className="rc-GlobalFooter_column_dropdown_links_content">
          <LinkList links={links} extraClassName={extraClassName} />
          {Array.isArray(boldLinks) && <LinkList links={boldLinks} boldList extraClassName={extraClassName} />}
        </div>
      </div>
    </div>
  );
}
