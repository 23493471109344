import { useId, useLocalizedStringFormatter } from '@coursera/cds-common';

import ButtonBase from '@core/ButtonBase';

import getSearchClearButtonCss, { classes } from './getSearchClearButtonCss';
import i18nMessages from './i18n';

type Props = {
  onClear: () => void;
  disabled?: boolean;
};

/**
 * Custom icon button for clearing the search input
 */
export const SearchClearButton = (props: Props) => {
  const { onClear, disabled } = props;
  const stringFormatter = useLocalizedStringFormatter(i18nMessages);
  const id = useId();

  return (
    <ButtonBase
      aria-label={stringFormatter.format('clear_search')}
      className={classes.searchClearButton}
      css={getSearchClearButtonCss}
      disabled={disabled}
      onClick={onClear}
    >
      {/*
        Icon is not exported from @coursera/cds-icons package because it's not used in the product. The source SVG is exported from Figma.
      */}
      <svg
        aria-hidden={true}
        className={classes.searchClearIcon}
        fill="none"
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          height="20"
          id={id}
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="20"
          x="0"
          y="0"
        >
          <path d="M0 0h20v20H0z" fill="#D9D9D9" />
        </mask>
        <g mask={`url(#${id})`}>
          <path d="m10 11.063 2.417 2.416c.152.153.33.226.53.219a.769.769 0 0 0 .532-.24.729.729 0 0 0 0-1.061L11.063 9.99l2.416-2.408a.727.727 0 0 0 0-1.061.73.73 0 0 0-1.062 0L10 8.938 7.583 6.52a.718.718 0 0 0-.52-.23.718.718 0 0 0-.521.23.729.729 0 0 0-.002 1.062L8.937 10l-2.416 2.417a.681.681 0 0 0-.219.52.758.758 0 0 0 .24.521.729.729 0 0 0 1.06.002L10 11.062ZM10.006 18a7.81 7.81 0 0 1-3.11-.625 8.064 8.064 0 0 1-2.552-1.719 8.065 8.065 0 0 1-1.719-2.551A7.819 7.819 0 0 1 2 9.99c0-1.104.208-2.14.625-3.105a8.066 8.066 0 0 1 4.27-4.26A7.819 7.819 0 0 1 10.01 2a7.75 7.75 0 0 1 3.105.625 8.082 8.082 0 0 1 4.26 4.265A7.77 7.77 0 0 1 18 9.994a7.81 7.81 0 0 1-.625 3.11 8.063 8.063 0 0 1-1.719 2.552 8.081 8.081 0 0 1-2.546 1.719 7.77 7.77 0 0 1-3.104.625ZM10 16.5c1.806 0 3.34-.632 4.604-1.896C15.868 13.34 16.5 11.806 16.5 10s-.632-3.34-1.896-4.604C13.34 4.132 11.806 3.5 10 3.5s-3.34.632-4.604 1.896C4.132 6.66 3.5 8.194 3.5 10s.632 3.34 1.896 4.604C6.66 15.868 8.194 16.5 10 16.5Z" />
        </g>
      </svg>
    </ButtonBase>
  );
};
