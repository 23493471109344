import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import user from 'js/lib/user';

import { useEnterpriseRoles } from 'bundles/enterprise-admin-navigation/utils/withManagedOrganizations';
import EnterpriseProgramMembershipsQuery from 'bundles/user-consent/queries/EnterpriseProgramMembershipsQuery.graphql';
import type {
  EnterpriseProgramMembershipsQuery as EnterpriseProgramMembershipsData,
  EnterpriseProgramMembershipsQueryVariables,
} from 'bundles/user-consent/queries/__generated__/EnterpriseProgramMembershipsQuery';
import type { EnterpriseUserInfo } from 'bundles/user-consent/types';
import { isRestrictedEnterpriseUser } from 'bundles/user-consent/utils/experimentUtils';

/**
 * Custom hook which makes 2 Enterprise calls to
 * 1. Identify if the user is an restricted Enterprise user,from whom we should hide the modal from.
 * 2. Identify whether the user is an Enterprise Admin.
 *
 * Enterprise User includes 2 types of users:
 * Enterprise Admin: Users with administrator roles within the Enterprise domain.
 * Enterprise Learner: Regular Enterprise users that joined one or more Enterprise programs.

 * @param skipEnterpriseQueries - Optional flag indicating whether to skip enterprise queries.
 * @param userAccountId - Optional userAccountId to fetch enterprise info for a specific user.
 * @returns {isEnterpriseAdmin} if the learner is an Enterprise Admin.
 * @returns {isRestrictedEnterpriseUser} if the learner is in the marketing comms blacklist.
 * @throws {Error} If error fetching program memberships
 */
const useEnterpriseUserInfo = (skipEnterpriseQueries = false, userAccountId?: number): EnterpriseUserInfo => {
  const userId = userAccountId || user.get().id;

  const { data, loading: isEnterpriseProgramMembershipLoading } = useQuery<
    EnterpriseProgramMembershipsData,
    EnterpriseProgramMembershipsQueryVariables
  >(EnterpriseProgramMembershipsQuery, {
    skip: skipEnterpriseQueries,
    variables: { userId, includeInvitations: true },
    context: { clientName: 'gatewayGql' },
    errorPolicy: 'all',
    onError: (error) => {
      Sentry.captureException(error, {
        extra: { message: 'Error fetching enterprise program memberships in useEnterpriseLearnerData.' },
      });
    },
  });

  const enterpriseLearnerData = data?.ProgramMemberships?.queryProgramMembershipsByUserIdAndIncludeInvitations;
  const enterpriseLeanerOrganizationIds =
    enterpriseLearnerData?.edges
      ?.map((element) => element?.node?.program?.naptimeThirdPartyOrganization?.id ?? '')
      .filter((id) => id) ?? [];

  const {
    organizationIds: enterpriseAdminOrganizationIds,
    isEnterpriseAdmin,
    loading: isEnterpriseRolesLoading,
  } = useEnterpriseRoles(userId, skipEnterpriseQueries);

  const filteredOrganizationIds = [...new Set([...enterpriseAdminOrganizationIds, ...enterpriseLeanerOrganizationIds])];
  const isLoading = isEnterpriseProgramMembershipLoading || isEnterpriseRolesLoading;

  return {
    isRestrictedEnterpriseUser: isRestrictedEnterpriseUser(filteredOrganizationIds),
    isEnterpriseAdmin,
    isLoading,
  };
};

export default useEnterpriseUserInfo;
