import type { AriaAttributes } from 'react';
import React, { useState, useCallback } from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';
import {
  AddIcon,
  ArrowPreviousIcon,
  BellIcon,
  CartIcon,
  ChevronNextIcon,
  ChevronPreviousIcon,
  CloseIcon,
  DownloadIcon,
  DragHandleIcon,
  EditIcon,
  EmailIcon,
  HomeIcon,
  LockIcon,
  MaximizeIcon,
  MenuIcon,
  MinimizeIcon,
  MoreActionsIcon,
  SearchIcon,
  SettingsIcon,
  TrashIcon,
  UnlockIcon,
  UploadIcon,
  NewChatIcon,
  MenuSwitcherIcon,
  MentionIcon,
  SendIcon,
  FilterIcon,
} from '@coursera/cds-icons';

import { Tooltip } from '@core/Tooltip';

import messages from './i18n';
import type { BaseProps } from './IconButtonBase';
import IconButtonBase from './IconButtonBase';
import { classes } from './iconButtonCss';

type Intent =
  | 'edit'
  | 'delete'
  | 'dragVertically'
  | 'email'
  | 'setting'
  | 'search'
  | 'menu'
  | 'next'
  | 'previous'
  | 'back'
  | 'cart'
  | 'notifications'
  | 'more'
  | 'expand'
  | 'collapse'
  | 'add'
  | 'lock'
  | 'unlock'
  | 'download'
  | 'upload'
  | 'home'
  | 'close'
  | 'atSign'
  | 'menuSwitcher'
  | 'newChat'
  | 'send'
  | 'filter';

/**
 * Custom type to accommodate either passing `intent` with internal icon mapping,
 * or use custom intents with custom icons requiring proper acceessible labels.
 */
type IntentOrIcon =
  | {
      /**
       * Setting the `intent` renders the proper icon for the intent as well
       * sets the default `aria-label` and `tooltip`.
       *
       * Custom intents:
       *   - for custom intents, use `icon` with `aria-label` and `tooltip`<div className=""></div>
       *   - Use a custom intent ONLY for exception cases.
       *   - Reach out to #lee-cds before using custom intents to make
       *    sure they are compliant from design+a11y perspective.
       */
      intent: Intent;

      icon?: never;

      /**
       * Visually displayed label inside the tooltip.
       * This is required when using custom intents.
       */
      tooltip?: string | IntentLabel;
    }
  | {
      intent?: never;

      /**
       * Custom icon to use for the custom intent.
       * This is required when using custom intents.
       * Make sure to also pass `aria-label` and `tooltip` when using custom intents.
       */
      icon: React.ReactElement;

      /**
       * Visual tooltip label, required for custom intents
       */
      tooltip: string;

      /**
       * Accessible label for screenreaders, required for custom intents.
       */
      'aria-label': string;
    };

type IntentLabel = (intentLabel: string) => string;

const intentMap: Record<Intent, React.ReactElement> = {
  edit: <EditIcon />,
  delete: <TrashIcon />,
  dragVertically: <DragHandleIcon />,
  email: <EmailIcon />,
  setting: <SettingsIcon />,
  search: <SearchIcon />,
  menu: <MenuIcon />,
  next: <ChevronNextIcon />,
  previous: <ChevronPreviousIcon />,
  back: <ArrowPreviousIcon />,
  cart: <CartIcon />,
  notifications: <BellIcon />,
  more: <MoreActionsIcon />,
  expand: <MaximizeIcon />,
  collapse: <MinimizeIcon />,
  add: <AddIcon />,
  lock: <LockIcon />,
  unlock: <UnlockIcon />,
  download: <DownloadIcon />,
  upload: <UploadIcon />,
  home: <HomeIcon />,
  close: <CloseIcon />,
  atSign: <MentionIcon />,
  menuSwitcher: <MenuSwitcherIcon />,
  newChat: <NewChatIcon />,
  send: <SendIcon />,
  filter: <FilterIcon />,
};

type IconButtonBaseProps = IntentOrIcon &
  BaseProps & {
    /**
     * Tooltip placement. If a collision happens placement will be handled automatically.
     * @default top
     */
    tooltipPlacement?: 'top' | 'bottom';

    /**
     * Tooltip optional visual appearance. If set to true, tooltip will not show.
     * @default false
     */
    hideTooltip?: boolean;

    /**
     * The `aria-label` attribute defines a string value that labels an interactive element.
     * Will be announced by screenreaders instead of `tooltip`.
     */
    'aria-label'?: string | IntentLabel;

    /**
     * The `aria-labelledby` attribute identifies the element (or elements) that labels
     * the element it is applied to.
     */
    'aria-labelledby'?: string;

    /**
     * Renders component with disabled styles but allows to focus it with `Tab` key.
     */
    'aria-disabled'?: AriaAttributes['aria-disabled'];
  };

export interface IconButtonTypeMap<
  Component extends React.ElementType = 'button'
> {
  props: IconButtonBaseProps;
  defaultComponent: Component;
}

export type IconButtonProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent']
> = OverrideProps<IconButtonTypeMap<D>, D> & { component?: React.ElementType };

/**
 * Icon button is a foundation component that can only be used within other experience components, such as pagination or breadcrumbs.
 *
 */
const IconButton: OverridableComponent<IconButtonTypeMap> = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(function IconButton(props, ref) {
  const {
    size = 'medium',
    variant = 'primary',
    disabled,
    intent,
    icon,
    tooltip,
    className,
    tooltipPlacement = 'top',
    hideTooltip = false,
    'aria-label': ariaLabel,
    'aria-disabled': ariaDisabled,
    onClick,
    ...rest
  } = props;

  const isCustomIntent = typeof intent === 'undefined';
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- TS 4.1.5 doesn't recognize isCustomIntent guard
  const intentIcon = isCustomIntent ? icon : intentMap[intent!];

  const [open, setOpen] = useState(false);

  const stringFormatter = useLocalizedStringFormatter(messages);

  let accessibleLabel = isCustomIntent
    ? (ariaLabel as string)
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- TS 4.1.5 doesn't recognize isCustomIntent guard
      stringFormatter.format(intent!);

  let tooltipLabel = isCustomIntent
    ? (tooltip as string)
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- TS 4.1.5 doesn't recognize isCustomIntent guard
      stringFormatter.format(intent!);

  if (ariaLabel) {
    accessibleLabel =
      typeof ariaLabel === 'function' ? ariaLabel(accessibleLabel) : ariaLabel;
  }

  if (tooltip) {
    tooltipLabel =
      typeof tooltip === 'function' ? tooltip(tooltipLabel) : tooltip;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose();

    onClick?.(event);
  };

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const buttonComponent = (
    <IconButtonBase
      ref={ref}
      aria-describedby={undefined}
      aria-disabled={disabled || ariaDisabled}
      /** Override prop because we don't want Tooltip content to be announced */
      aria-label={accessibleLabel}
      className={clsx(className, {
        [classes[size]]: true,
        [classes[variant]]: true,
        [classes.disabled]: disabled || ariaDisabled,
      })}
      /** Override prop propagated from the Tooltip */
      component={disabled ? 'span' : 'button'}
      disabled={disabled}
      /** Use span as component to avoid MUI Tooltip warning when button is disabled */
      /** causes issues when button has role span and disabled MUI turns disabled into aria-disabled */
      size={size}
      title={undefined}
      /** Override title propagated from the Tooltip */
      variant={variant}
      onClick={handleClick}
      {...rest}
    >
      {intentIcon}
    </IconButtonBase>
  );

  return hideTooltip ? (
    buttonComponent
  ) : (
    <Tooltip
      arrow
      invert={variant === 'ghostInvert' || variant === 'primaryInvert'}
      open={open}
      placement={tooltipPlacement}
      title={tooltipLabel}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {buttonComponent}
    </Tooltip>
  );
});

export default IconButton;
