import { useEffect } from 'react';

import type { DescriptionPage_PremiumExperienceVariant as PremiumExperienceVariant } from '__generated__/graphql-types';

import cookie from 'js/lib/cookie';
import store from 'js/lib/coursera.store';
import fullStory from 'js/lib/fullStoryUtils';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';

import {
  getIsCourseraLiteLandingPageEnabled,
  previewIsCourseraLiteLandingPageEnabled,
} from 'bundles/coursera-plus-landing-page/contentful/constants';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
import type SubscriptionsV1 from 'bundles/naptimejs/resources/subscriptions.v1';

type TiersV3VariantInputType = {
  hasSpecialization?: boolean;
  skipProductTypeCheck?: boolean;
  partners?: Partners;
  isStandaloneCourseEligibleForTiersEnroll?: boolean;
};

export const previewSubscriptionTiersVariant = () => courseraPlusExperiments.preview('subscriptionTiersLiteVariant');

export const getIsLearnerImpressedInIndiaTiersEpic = () => {
  // Recording impressions here for monitoring purposes
  return courseraPlusExperiments.get('isLearnerImpressedInIndiaTiersEpic');
};

export const previewTiersFromStandaloneCoursesVariant = () =>
  courseraPlusExperiments.preview('tiersEnrollmentFromStandaloneCoursesVariant');

// IMPORTANT: do not use this function to decide whether or not to show tiers enroll modal
// since one of the 'active' variants is not supposed to display modal
export const isTiersFromStandaloneCoursesEnabled = () => {
  return previewTiersFromStandaloneCoursesVariant() === '3';
};

export const isSubscriptionTiersVariantA = () => previewSubscriptionTiersVariant() === 'A';

export const isSubscriptionTiersVariantB = () => previewSubscriptionTiersVariant() === 'B';

export const isSubscriptionTiersVariantC = () => previewSubscriptionTiersVariant() === 'C';

export const previewLiteEnrollModalDesignTweaksVariant = () =>
  courseraPlusExperiments.preview('liteEnrollmentModalDesignTweaksVariant');

export const previewLiteEnrollModalDesignTweaksVariantVersion2 = () =>
  courseraPlusExperiments.preview('liteEnrollmentModalDesignTweaksVariantVersion2');

export const isLiteEnrollModalDesignTweaksEnabled = () =>
  !['control', 'optOut'].includes(previewLiteEnrollModalDesignTweaksVariant()) ||
  !['control', 'optOut'].includes(previewLiteEnrollModalDesignTweaksVariantVersion2());

export const previewIsIndiaTiersPriceEpicEnabled = () =>
  courseraPlusExperiments.preview('subscriptionTiersPricingTestVariantIndia') !== 'optOut';

export const isLiteEnrollModalDesignTweaksVariant1 = () => {
  return (
    previewLiteEnrollModalDesignTweaksVariant() === '1' || previewLiteEnrollModalDesignTweaksVariantVersion2() === '1'
  );
};

export const isLiteEnrollModalDesignTweaksVariant2 = () => {
  return (
    previewLiteEnrollModalDesignTweaksVariant() === '2' || previewLiteEnrollModalDesignTweaksVariantVersion2() === '2'
  );
};

export const isLiteEnrollModalDesignTweaksVariant3 = () => previewLiteEnrollModalDesignTweaksVariant() === '3';

export const isLiteEnrollModalDesignTweaksVariant4 = () => previewLiteEnrollModalDesignTweaksVariant() === '4';

export const isLiteEnrollModalDesignTweaksVariant5 = () => {
  return (
    previewLiteEnrollModalDesignTweaksVariant() === '5' || previewLiteEnrollModalDesignTweaksVariantVersion2() === '5'
  );
};

export const isLiteSideBySideEnrollModalShown = () =>
  isSubscriptionTiersVariantA() || isSubscriptionTiersVariantB() || isLiteEnrollModalDesignTweaksEnabled();

// Only one of these tests will be enabled at any time since they are mutually exclusive
// either by country targeting on the epic or by the targeting rule on the epic.
export const previewPricingTestVariant = (tiersV3VariantInput?: TiersV3VariantInputType) => {
  const {
    hasSpecialization,
    skipProductTypeCheck = false,
    isStandaloneCourseEligibleForTiersEnroll,
  } = tiersV3VariantInput ?? {};
  const tiersOnStandaloneCoursesVariant = courseraPlusExperiments.preview(
    'tiersEnrollmentFromStandaloneCoursesVariant'
  );
  if (courseraPlusExperiments.preview('subscriptionTiersPricingTestVariant') !== 'optOut') {
    return courseraPlusExperiments.preview('subscriptionTiersPricingTestVariant');
  } else if (courseraPlusExperiments.preview('subscriptionTiersPricingTestVariantIndia') !== 'optOut') {
    return courseraPlusExperiments.preview('subscriptionTiersPricingTestVariantIndia');
  } else if (tiersOnStandaloneCoursesVariant !== 'optOut') {
    if (hasSpecialization || isStandaloneCourseEligibleForTiersEnroll || skipProductTypeCheck) {
      return tiersOnStandaloneCoursesVariant;
    } else {
      return 'optOut';
    }
  } else if (courseraPlusExperiments.preview('subscriptionTiersPricingVariantLowIncomeCountries') !== 'optOut') {
    return courseraPlusExperiments.preview('subscriptionTiersPricingVariantLowIncomeCountries');
  } else {
    return 'optOut';
  }
};

// These partner IDs are for Google and we are excluding Google from the pricing test.
// You may notice that we are not excluding Google Cloud and that is intentional.
export const TIERS_PRICE_TEST_EXCLUDED_PARTNERS = [318, 330, 463, 481];

const PEPSICO_PARTNER_ID = 1586;

// Exclude the following partners from the India price test
// - All Google partners (Google Cloud partner as well - 443)
// - PepsiCo partner (PEPSICO_PARTNER_ID - 1586)
export const TIERS_PRICE_TEST_EXCLUDED_PARTNERS_IN_INDIA = [318, 330, 443, 463, 481, PEPSICO_PARTNER_ID];

export const getIsPartnerExcludedFromPricingTest = (excludedPartners: number[], partners?: Partners) => {
  const isPartnerExcludedFromPricingTest = partners
    ?.map((p) => Number(p?.id))
    ?.some((partnerId) => excludedPartners.includes(partnerId));

  return Boolean(isPartnerExcludedFromPricingTest);
};

export const getIsPartnerExcludedFromTiersFromStandaloneCoursesTest = (partners?: Partners) => {
  const isPartnerExcludedFromTiersFromStandaloneCoursesTest = partners
    ?.map((p) => String(p?.id)) // Epic tag targeting values are stored as strings
    ?.some((partnerId) =>
      courseraPlusExperiments.get('tiersFromStandaloneCoursesPartnerExclusionEnabled', { partner_id: partnerId })
    );

  return Boolean(isPartnerExcludedFromTiersFromStandaloneCoursesTest);
};

// The original, relaunch, and pricing test experiments are all mutually exclusive, so a user can only be opted in to one experiment at a time
export const previewSubscriptionTiersDesignV3Variant = (tiersV3VariantInput?: TiersV3VariantInputType) => {
  const { partners } = tiersV3VariantInput ?? {};
  let isPartnerExcluded = getIsPartnerExcludedFromPricingTest(TIERS_PRICE_TEST_EXCLUDED_PARTNERS, partners);
  if (isTiersFromStandaloneCoursesEnabled()) {
    isPartnerExcluded = getIsPartnerExcludedFromTiersFromStandaloneCoursesTest(partners);
  }

  if (courseraPlusExperiments.preview('subscriptionTiersDesignV3Variant') !== 'optOut') {
    return courseraPlusExperiments.preview('subscriptionTiersDesignV3Variant');
  } else if (courseraPlusExperiments.preview('subscriptionTiersDesignV3RelaunchVariant') !== 'optOut') {
    return courseraPlusExperiments.preview('subscriptionTiersDesignV3RelaunchVariant');
  } else if (previewPricingTestVariant(tiersV3VariantInput) !== 'optOut') {
    if (previewIsIndiaTiersPriceEpicEnabled()) {
      return getIsPartnerExcludedFromPricingTest(TIERS_PRICE_TEST_EXCLUDED_PARTNERS_IN_INDIA, partners)
        ? 'optOut'
        : previewPricingTestVariant({});
    }

    if (isPartnerExcluded) {
      return 'optOut';
    } else {
      return previewPricingTestVariant(tiersV3VariantInput);
    }
  } else {
    return 'optOut';
  }
};

export const isSubscriptionTiersDesignV3Enabled = (tiersV3VariantInput?: TiersV3VariantInputType) =>
  !['control', 'optOut'].includes(previewSubscriptionTiersDesignV3Variant(tiersV3VariantInput));

export const isSubscriptionTiersDesignV3Variant1 = () => previewSubscriptionTiersDesignV3Variant() === '1';

export const isSubscriptionTiersDesignV3Variant2 = () => previewSubscriptionTiersDesignV3Variant() === '2';

export const isSubscriptionTiersDesignV3Variant3 = (tiersV3VariantInput?: TiersV3VariantInputType) =>
  previewSubscriptionTiersDesignV3Variant(tiersV3VariantInput) === '3';

export const isSubscriptionTiersDesignV3Variant4 = () => previewSubscriptionTiersDesignV3Variant() === '4';

export const isSubscriptionTiersDesignV3Variant5 = () => previewSubscriptionTiersDesignV3Variant() === '5';

export type Partners = Array<{ id: string | number } | undefined> | null;

export const generateSubscriptionTiersImpression = ({
  hasSpecialization,
  partners,
  isStandaloneCourseEligibleForTiersEnroll,
  hasPromotion,
}: {
  hasSpecialization: boolean;
  partners?: Partners;
  isStandaloneCourseEligibleForTiersEnroll?: boolean;
  hasPromotion?: boolean;
}) => {
  if (!hasPromotion) {
    courseraPlusExperiments.get('subscriptionTiersLiteVariant');
    courseraPlusExperiments.get('liteEnrollmentModalDesignTweaksVariant');
    courseraPlusExperiments.get('liteEnrollmentModalDesignTweaksVariantVersion2');

    // For Tiers Design v3, only impress for Specialzation and course part of Specialization (exclude standalone courses)
    if (hasSpecialization) {
      courseraPlusExperiments.get('subscriptionTiersDesignV3Variant');
      courseraPlusExperiments.get('subscriptionTiersDesignV3RelaunchVariant');

      // In the Subscription Tiers pricing test that uses the v3 design, we want to exclude certain partners.
      // we are only recording impressions if the content is not from a blocked partner.
      if (!getIsPartnerExcludedFromPricingTest(TIERS_PRICE_TEST_EXCLUDED_PARTNERS, partners)) {
        courseraPlusExperiments.get('subscriptionTiersPricingTestVariant');
        courseraPlusExperiments.get('subscriptionTiersPricingVariantLowIncomeCountries');
      }
    }
  }
};

export const generateImpressionForTiersEnrollmentFromStandaloneCourses = ({
  hasSpecialization,
  partners,
  isStandaloneCourseEligibleForTiersEnroll,
  hasPromotion,
}: {
  hasSpecialization: boolean;
  partners?: Partners;
  isStandaloneCourseEligibleForTiersEnroll?: boolean;
  hasPromotion?: boolean;
}) => {
  const isPartnerExcluded = getIsPartnerExcludedFromTiersFromStandaloneCoursesTest(partners);

  if (!hasPromotion && !isPartnerExcluded && (hasSpecialization || isStandaloneCourseEligibleForTiersEnroll)) {
    courseraPlusExperiments.get('tiersEnrollmentFromStandaloneCoursesVariant');
  }
};

export const getIsStandaloneCourseEligibleForTiersEnroll = ({
  premiumExperienceVariant,
  hasSpecialization,
  isClosedCourse: isClosedCourseArg,
  enrollmentAvailableChoices,
}: {
  premiumExperienceVariant?: PremiumExperienceVariant | null;
  hasSpecialization: boolean;
  isClosedCourse?: boolean;
  enrollmentAvailableChoices?: EnrollmentChoices;
}) => {
  const isClosedCourse = premiumExperienceVariant === 'PREMIUM_COURSE' || isClosedCourseArg;
  return !hasSpecialization && !isClosedCourse && enrollmentAvailableChoices?.canPurchaseSingleCourse;
};

type IndiaTiersGenerateImpressionInputType = {
  isCourseraPlusEligibleProduct?: boolean;
  hasSpecialization?: boolean;
  partners?: Partners;
};

export const useGenerateIndiaTiersTestImpression = ({
  isCourseraPlusEligibleProduct,
  hasSpecialization,
  partners,
}: IndiaTiersGenerateImpressionInputType) => {
  useEffect(() => {
    const shouldRecordImpression =
      isCourseraPlusEligibleProduct &&
      hasSpecialization &&
      !getIsPartnerExcludedFromPricingTest(TIERS_PRICE_TEST_EXCLUDED_PARTNERS_IN_INDIA, partners) &&
      user.isAuthenticatedUser();

    if (shouldRecordImpression) {
      courseraPlusExperiments.get('subscriptionTiersPricingTestVariantIndia');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSpecialization, partners?.[0]?.id, isCourseraPlusEligibleProduct]);
};

export const isSubscriptionTiersEnabled = (tiersV3VariantInput?: TiersV3VariantInputType) =>
  !['control', 'optOut'].includes(previewSubscriptionTiersVariant()) ||
  getIsCourseraLiteLandingPageEnabled() ||
  isLiteEnrollModalDesignTweaksEnabled() ||
  isSubscriptionTiersDesignV3Enabled(tiersV3VariantInput);

// This function returns true if a learner is not impressed into any subscription tiers experiments
export const isSubscriptionTiersOptedOut = () =>
  [
    previewSubscriptionTiersVariant(),
    previewLiteEnrollModalDesignTweaksVariant(),
    previewLiteEnrollModalDesignTweaksVariantVersion2(),
    previewSubscriptionTiersDesignV3Variant({ skipProductTypeCheck: true }),
  ].every((value) => value === 'optOut') && !previewIsCourseraLiteLandingPageEnabled();

export const initSubscriptionTiersFullStory = () => {
  if (courseraPlusExperiments.get('subscriptionTiersFullStory')) {
    fullStory.init();
    fullStory.set({
      subscriptionTiersVariant_str: previewSubscriptionTiersVariant(), // eslint-disable-line camelcase
      liteEnrollmentModalDesignTweaksVariant_str: previewLiteEnrollModalDesignTweaksVariant(), // eslint-disable-line camelcase
      liteEnrollmentModalDesignTweaksVariantVersion2_str: previewLiteEnrollModalDesignTweaksVariantVersion2(), // eslint-disable-line camelcase
      subscriptionTiersDesignV3Variant_str: previewSubscriptionTiersDesignV3Variant(), // eslint-disable-line camelcase
    });
  }
};

export const WELCOME_MODAL_KEY = 'subscription_tiers_welcome_modal';

export const UPGRADE_MESSAGE_KEY = 'subscription_tiers_upgrade_message';

const getHasSeen = (cookieKey: string) => {
  const cookieValue = cookie.get(cookieKey);
  return !!cookieValue?.includes(tupleToStringKey([user.get().id, cookieKey]));
};

export const getHasSeenSubscriptionTiersWelcomeModal = () => getHasSeen(WELCOME_MODAL_KEY);

export const getHasSeenSubscriptionTiersUpgradeMessage = () => getHasSeen(UPGRADE_MESSAGE_KEY);

const setHasSeen = (cookieKey: string) => {
  const value = cookie.get(cookieKey) ?? '';
  cookie.set(cookieKey, `${value},${tupleToStringKey([user.get().id, cookieKey])}`);
};

export const setHasSeenSubscriptionTiersWelcomeModal = () => setHasSeen(WELCOME_MODAL_KEY);

export const setHasSeenSubscriptionTiersUpgradeMessage = () => setHasSeen(UPGRADE_MESSAGE_KEY);

export const isValidCourseraLiteSubscription = (subscription: SubscriptionsV1) => {
  const { isCourseraLiteSubscription, isFreeTrial, isActive, isCancelled } = subscription;
  // Adding the isCancelled check here because a learner can still have access to Lite
  // for the remainder of their billing cycle days after they cancelled the subscription
  return isCourseraLiteSubscription && (isFreeTrial || isActive || isCancelled);
};

const REDIRECT_URL_AFTER_LITE_UPGRADE_KEY = 'redirectUrlAfterUpgrade';
const HAS_UPGRADED_SUCCESSFULLY_KEY = 'upgradeSuccessful';
const INDIA_LITE_TO_PLUS_UPGRADE_DATA_KEY = 'indiaLiteToPlusUpgradeData';

export const markUpgradeAsSuccessful = () => {
  store.set(HAS_UPGRADED_SUCCESSFULLY_KEY, `${user.get().id}`);
};

const getIsUpgradeSuccessful = () => {
  const isUpgradeSuccessful = store.get(HAS_UPGRADED_SUCCESSFULLY_KEY) || undefined;
  return isUpgradeSuccessful?.toString() === `${user.get()?.id}`;
};

export const saveRedirectUrlAfterUpgrade = () => {
  const currentUrl = typeof window !== 'undefined' ? window.location.pathname : undefined;
  store.set(REDIRECT_URL_AFTER_LITE_UPGRADE_KEY, currentUrl);
};

export const getRedirectUrlAfterUpgrade = () => {
  const redirectUrl = store.get(REDIRECT_URL_AFTER_LITE_UPGRADE_KEY) || undefined;
  return getIsUpgradeSuccessful() ? redirectUrl : undefined;
};

export const removeRedirectUrlAfterUpgrade = () => {
  store.remove(HAS_UPGRADED_SUCCESSFULLY_KEY);
  store.remove(REDIRECT_URL_AFTER_LITE_UPGRADE_KEY);
  store.remove(INDIA_LITE_TO_PLUS_UPGRADE_DATA_KEY);
};

export const redactPayPalEmail = (email: string) => {
  const [first, last] = email.split('@');
  const firstRedacted = first.slice(0, 2) + '*'.repeat(first.length - 2);
  return `${firstRedacted}@${last}`;
};

type IndiaUpgradeData = { liteSubscriptionId: string; s12nId?: string; courseId?: string; userId: string };

export const getIndiaTiersUpgradeData = (): IndiaUpgradeData | undefined => {
  const rawData = store.get(INDIA_LITE_TO_PLUS_UPGRADE_DATA_KEY);
  return rawData ? JSON.parse(rawData) : undefined;
};

export const setIndiaTiersUpgradeData = (data: { liteSubscriptionId: string; s12nId?: string; courseId?: string }) => {
  if (data) {
    store.set(INDIA_LITE_TO_PLUS_UPGRADE_DATA_KEY, JSON.stringify({ ...data, userId: user.get().id }));
  }
};

// This function returns true if a learner is impressed into any of the subscription
// tiers experiment variants in which the price of Coursera Plus is $49 USD
export const getIsLearnerIn49USDSubscriptionTiersVariant = () =>
  isSubscriptionTiersEnabled({ skipProductTypeCheck: true }) ||
  previewLiteEnrollModalDesignTweaksVariant() === 'control' ||
  previewPricingTestVariant({ skipProductTypeCheck: true }) === '3';
