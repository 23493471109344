import * as Sentry from '@sentry/react';

import type DeviceProvider from './device.provider';
import { createDefaultDeviceProvider } from './device.provider';
import type { onEvents } from './emitter';
import Emitter from './emitter';
import Envelope from './envelope';
import type Events from './events';
import type SessionProvider from './session.provider';
import { createDefaultSessionProvider } from './session.provider';
import { createDefaultTransport } from './transport';
import type { Transport } from './transport';

type Configuration = {
  batchUrl?: string;
  url?: string;
  version?: string;
};

type Middleware = <K extends keyof Events>(eventName: K, data: Events[K]) => Events[K] | Promise<Events[K]>;

type Options = Configuration &
  onEvents & {
    device?: DeviceProvider;
    session?: SessionProvider;
    transport?: Transport;
    type?: 'web' | 'server';
  };

type Plugin = (client: Client) => void;

declare global {
  interface Window {
    appName?: string;
  }
}

/**
 * Client that sends events to the server.
 *
 * This class is responsible for managing the lifecycle of events, including
 * creating, sending, and handling errors. It uses device and session providers
 * to gather necessary context and a transport mechanism to send the events.
 */
class Client extends Emitter<onEvents> {
  /**
   * The device provider instance.
   */
  declare readonly device: DeviceProvider;

  /**
   * The session provider instance.
   */
  declare readonly session: SessionProvider;

  /**
   * The transport mechanism for sending events.
   * @private
   */
  private declare readonly transport: Transport;

  /**
   * The type of the client, indicating the environment ('web' or 'server').
   */
  declare readonly type: 'web' | 'server';

  /**
   * The version of the client.
   */
  readonly version: string = '2.1.0';

  /**
   * Array of middleware functions to process events before sending.
   * @private
   */
  private middlewares: Middleware[] = [];

  /**
   * Constructor for the Client class.
   *
   * @param options - An object containing configuration options, event handlers, and providers.
   */
  constructor(options: Options = {}) {
    super();

    this.device = options.device ?? createDefaultDeviceProvider();
    this.session = options.session ?? createDefaultSessionProvider();
    this.transport =
      options.transport ??
      createDefaultTransport({
        batchUrl: options.batchUrl,
        url: options.url,
      });

    this.type = options.type ?? 'web';

    this.version = options.version ?? '2.1.0';
  }

  /**
   * Send an event to the server.
   *
   * @param eventName - The name of the event.
   * @param input - The data associated with the event.
   */
  async sendEvent<K extends keyof Events>(eventName: K, input: Events[K]): Promise<void> {
    // Create a new session if the current one is expired
    if (this.session.get().isExpired()) {
      // Reset the session
      this.session.reset();

      // Session has started
      this.trigger('sessionStart', this.session.get());
    }

    // Store the updated session and device data
    this.session.touch();
    this.device.touch();

    // Process the event data through middleware
    const data = await this.middlewares.reduce<Promise<Events[K]>>(
      async (acc, middleware) => middleware(eventName, await acc),
      Promise.resolve(input)
    );

    this.trigger('beforeSend', eventName, data);

    // Wrap the data in an envelope for sending
    const envelope = new Envelope({
      app: window.appName ?? 'web',
      client: this,
      device: this.device.get(),
      key: eventName,
      session: this.session.get(),
      value: data,
    });

    try {
      await this.transport.send(envelope);
    } catch (e) {
      // We don't want to throw an error
      Sentry.captureException(e);
    }

    this.trigger('sent', eventName, data);
  }

  /**
   * Add a middleware function to process events before sending.
   *
   * @param callback - The middleware function.
   */
  middleware(callback: Middleware) {
    this.middlewares.push(callback);
  }

  /**
   * Register a plugin to extend the client's functionality.
   *
   * @param plugin - The plugin function.
   */
  register(plugin: Plugin) {
    plugin(this);
  }
}

export type { Plugin };

export default Client;
