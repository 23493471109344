import type { ButtonName } from '@coursera/event-pulse-types';
import { track } from '@coursera/event-pulse/sdk';

import _t from 'i18n!nls/page';

// The default/minimum height of the page header, as this is the default height of the mobile and desktop main navs.
export const DEFAULT_PAGE_HEADER_HEIGHT = 65;

export const PAGE_HEADER_Z_INDEX = 3000;

export const MOBILE_HEADER_BREAKPOINT = 1060;

export const DEFAULT_SUB_NAV_HEIGHT = 52;

export const MOBILE_MAIN_IDENTIFIER = {
  ACCOUNT_MENU: 'ACCOUNT_MENU',
  EXPLORE_MENU: 'EXPLORE_MENU',
  MAIN_MENU: 'MAIN_MENU',
} as const;

export const MOBILE_EXPLORE_IDENTIFIER = {
  DEGREES_MENU: 'DEGREES_MENU',
  CERTIFICATE_MENU: 'CERTIFICATE_MENU',
  DOMAIN_MENU: 'DOMAIN_MENU',
  ENTERPRISE_EXPLORE_SUBMENU: 'ENTERPRISE_EXPLORE_SUBMENU',
  CONSUMER_EXPLORE_SUBMENU: 'CONSUMER_EXPLORE_SUBMENU',
} as const;

export type MobileMainIdentifierKeys = keyof typeof MOBILE_MAIN_IDENTIFIER;
export type MobileMainIdentifierValues = (typeof MOBILE_MAIN_IDENTIFIER)[MobileMainIdentifierKeys];

export type MobileExploreIdentifierKeys = keyof typeof MOBILE_EXPLORE_IDENTIFIER;
export type MobileExploreIdentifierValues = (typeof MOBILE_EXPLORE_IDENTIFIER)[MobileExploreIdentifierKeys];

export const browseAllSubjectsButtonProps = () => ({
  href: '/browse',
  label: _t('Browse all subjects'),
  name: 'browse',
  onButtonClick: () => {
    track('click_megamenu_item', {
      megamenuItemLink: '/browse',
      megamenuItemName: 'browse',
      megamenuItemType: 'view_all_link',
      megamenuSection: 'subjects',
    });
  },
});

export const domainButtonProps = () => [
  {
    label: _t('Data Science'),
    name: 'data-science',
  },
  {
    label: _t('Business'),
    name: 'business',
  },
  {
    label: _t('Computer Science'),
    name: 'computer-science',
  },
  {
    label: _t('Information Technology'),
    name: 'information-technology',
  },
  {
    label: _t('Language Learning'),
    name: 'language-learning',
  },
  {
    label: _t('Health'),
    name: 'life-sciences',
  },
  {
    label: _t('Personal Development'),
    name: 'personal-development',
  },
  {
    label: _t('Physical Science and Engineering'),
    name: 'physical-science-and-engineering',
  },
  {
    label: _t('Social Sciences'),
    name: 'social-sciences',
  },
  {
    label: _t('Arts and Humanities'),
    name: 'arts-and-humanities',
  },
  {
    label: _t('Math and Logic'),
    name: 'math-and-logic',
  },
];

export const goalsButtonsProps = () => {
  const buttons = {
    freeCourse: {
      href: '/courses?query=free',
      label: _t('Take a free course'),
      name: 'free-course',
      onButtonClick: () => {
        track('click_megamenu_item', {
          megamenuItemLink: '/courses?query=free',
          megamenuItemName: 'free-course',
          megamenuItemType: 'other',
          megamenuSection: 'goals',
        });
      },
    },
    degree: {
      label: _t('Earn a Degree'),
      name: 'earn-a-degree',
      trackingName: 'mobile_header_degree_button',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'degree',
          megamenuSection: 'goals',
        });
      },
      exploreViewState: MOBILE_EXPLORE_IDENTIFIER.DEGREES_MENU,
      ariaLabel: _t('Go to degree navigation slide'),
    },
    cert: {
      label: _t('Earn a Certificate'),
      name: 'earn-a-certificate',
      trackingName: 'mobile_header_certificate_button',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'earn-a-certificate',
          megamenuSection: 'goals',
        });
      },
      exploreViewState: MOBILE_EXPLORE_IDENTIFIER.CERTIFICATE_MENU,
      ariaLabel: _t('Go to certificate navigation slide'),
    },
    career: {
      href: '/career-academy',
      label: _t('Find your new career'),
      name: 'career',
      onButtonClick: () => {
        track('view_megamenu_items', {
          megamenuItemName: 'find-new-career',
          megamenuSection: 'goals',
        });
      },
    },
  };
  return buttons;
};

/**
 * List of definitions for deep-linkable pages.
 * ORDER MATTERS! If there are multiple pattern matches, the first definition
 *   will take effect.
 *
 * @type {Array} List of {
 *   page: <String> human-readable, unique identifier for the page
 *   pattern: <RegExp> window pathname that the definition applies to
 *   webToMobilePathTranslator: <Function> that changes the window pathname
 *     to mobile deep-link target pathname.
 * }
 */
export const DEEP_LINKABLE_PAGES = [
  {
    page: 'browse-subdomain',
    pattern: /\/browse\/(.+)\/(.+)/,
    webToMobilePathTranslator: (path: string) => path,
  },
  {
    page: 'browse-domain',
    pattern: /\/browse\/([a-z]+)(.+)/,
    webToMobilePathTranslator: (path: string) => path,
  },
  {
    page: 'browse-root',
    pattern: /\/browse/,
    webToMobilePathTranslator: (path: string) => path.split('/').slice(0, 2).join('/'), // watch out for trailing '/'
  },
  {
    page: 'sdp',
    pattern: /\/specializations\/([a-z]+)(.+)/,
    webToMobilePathTranslator: (path: string) => path,
  },
  {
    page: 'ondemand-cdp-resource',
    pattern: /\/learn\/(.+)\/(supplement|exam|quiz|lecture)\/(.+)\/(.+)/,
    webToMobilePathTranslator: (path: string) => path.split('/').slice(0, 5).join('/'),
  },
  {
    page: 'ondemand-cdp-home-welcome',
    pattern: /\/learn\/(.+)\/home\/welcome/,
    webToMobilePathTranslator: (path: string) => path.split('/').slice(0, 5).join('/'),
  },
  {
    page: 'ondemand-cdp',
    pattern: /\/learn\/([^/]+)/,
    webToMobilePathTranslator: (path: string) => path,
  },
];

export const BANNER_COURSERA_LOGO_PATH = 'images/favicons/apple-touch-icon-144x144.png';

export const logInButtonProps = () => ({
  label: _t('Log In'),
  authMode: 'login' as ButtonName,
});

export const signUpButtonProps = () => ({
  label: _t('Join for Free'),
  authMode: 'signup' as ButtonName,
});
