// Do not change this import, otherwise it cannot be accessed the members of React.
// eslint-disable-next-line @coursera-web/custom-rules/import/no-react-default
import React from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DOM from 'react-dom-factories';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crea... Remove this comment to see the full error message
import createClassFactory from 'create-react-class/factory';
import PropTypes from 'prop-types';

// We delete these properties because of issues with re-defining properties that don't have setters.
// Ultimately, we want to make sure this works as expected with monkeypatched libraries, anyway.
// @ts-expect-error ts-migrate(2339) FIXME: Property 'PropTypes' does not exist on type 'typeo... Remove this comment to see the full error message
delete React.PropTypes;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'createClass' does not exist on type 'typ... Remove this comment to see the full error message
delete React.createClass;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'DOM' does not exist on type 'typeof impo... Remove this comment to see the full error message
delete React.DOM;

// @ts-expect-error ts-migrate(2339) FIXME: Property 'PropTypes' does not exist on type 'typeo... Remove this comment to see the full error message
React.PropTypes = PropTypes;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'createClass' does not exist on type 'typ... Remove this comment to see the full error message
React.createClass = createClassFactory(React.Component, React.isValidElement, new React.Component().updater);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'DOM' does not exist on type 'typeof impo... Remove this comment to see the full error message
React.DOM = DOM;

/**
 * @deprecated Use `Component` and explicitly add `children` to your component props
 */
export class LegacyComponentWithChildren<P, S> extends React.Component<React.PropsWithChildren<P>, S> {}
React.LegacyComponentWithChildren = LegacyComponentWithChildren;
/**
 * @deprecated Use `PureComponent` and explicitly add `children` to your props type
 */
class LegacyPureComponentWithChildren<P = {}, S = {}, SS = any> extends React.PureComponent<
  React.PropsWithChildren<P>,
  S,
  SS
> {}
React.LegacyPureComponentWithChildren = LegacyPureComponentWithChildren;

export const Children = React.Children;
export const Component = React.Component;
export const Fragment = React.Fragment;
export const PureComponent = React.PureComponent;
export const Suspense = React.Suspense;
export const cloneElement = React.cloneElement;
export const createContext = React.createContext;
export const createElement = React.createElement;
export const createRef = React.createRef;
export const forwardRef = React.forwardRef;
export const isValidElement = React.isValidElement;
export const lazy = React.lazy;
export const memo = React.memo;
export const useCallback = React.useCallback;
export const useContext = React.useContext;
export const useDebugValue = React.useDebugValue;
export const useEffect = React.useEffect;
export const useImperativeHandle = React.useImperativeHandle;
export const useLayoutEffect = React.useLayoutEffect;
export const useMemo = React.useMemo;
export const useReducer = React.useReducer;
export const useRef = React.useRef;
export const useState = React.useState;
export const version = React.version;
export { PropTypes };
export default React;
